import React from "react";
import PropTypes from "prop-types";
import Image from "components/Image";

import { Container, Row, Col } from "react-bootstrap";

import "./Footer.scss";

const Footer = ({ frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { imageFileName, logoCohete } = frontmatter;

  return (
    <footer className="footer pt-5">
      <Container className="align-items-center text-center">
        <Row>
          <Col>
            <Image className="img-fluid logo" fileName={imageFileName} alt={imageFileName} />
          </Col>
        </Row>
        <Row>
          <Col className="my-5">
            <Image className="img-fluid vendor" fileName={logoCohete} alt={logoCohete} />
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  frontmatter: PropTypes.object,
};

Footer.defaultProps = {
  frontmatter: null,
};

export default Footer;
