import React from "react";
import PropTypes from "prop-types";

import { Accordion, Card } from "react-bootstrap";
import Icon from "./Icon";


import "./SectionFaq.scss";

const SectionFaq = ({ ...faqs }) => {

  return (
    <>
      <Accordion className="mt-4">
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">
            <span>{faqs.question}</span>
            <Icon iconName="ChevronIcon" inverse size="4x" />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
            <span>{faqs.answer}</span>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  );
};

SectionFaq.propTypes = {
  faqs: PropTypes.object,
};

SectionFaq.defaultProps = {
  faqs: {},
};
export default SectionFaq;
