// Must be refactor mobile / desktop Col
import React from "react";
import PropTypes from "prop-types";

import { Row, Col, Button } from "react-bootstrap";
import useSmoothScrollTo from "hooks/useSmoothScrollTo";
import Image from "components/Image";

import "./Top.scss";

const Top = ({ frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { header, subheader, jumpToAnchor, jumpToAnchorText } = frontmatter;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const scrollToSection = useSmoothScrollTo(jumpToAnchor);

  let extraInfoPart;
  if (jumpToAnchor && jumpToAnchorText) {
    extraInfoPart = (
      <Button size="lg" variant="primary" onClick={scrollToSection}>
        {jumpToAnchorText}
      </Button>
    );
  }

  return (
    <>
      <div className="background-top  text-center top-section">
        <Row>
          <Col>
            <div className="inner-top">
              <Image className="img-fluid mb-5 px-3" fileName="logos.png" alt="Logo Vm Piscinas" />
              <h1>{header}</h1>
              <p className="my-5">{subheader}</p>
              <div className="top-actions">
                {extraInfoPart}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

Top.propTypes = {
  frontmatter: PropTypes.object,
};

Top.defaultProps = {
  frontmatter: null,
};

export default Top;
