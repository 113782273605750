import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import addToMailchimp from "gatsby-plugin-mailchimp";
import PropTypes from "prop-types";

import "./ContactForm.scss";

const ContactForm = ({ ...form }) => {
  const [email, setEmail] = useState("");
  const [text, settext] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Mailchimp always responds with status code 200, accompanied by a string indicating the result of the response.
    const { result, msg } = await addToMailchimp(email, {
      FNAME: name,
      PHONE: phone,
      TEXT: text,
    });
    if (result === "success") {
      settext("");
      setName("");
      setPhone("");
    }
    // Removes the HTML returned in some response messages in case of error
    setMessage(msg.split("<")[0]);
    setStatus(result);
  };

  const handleName = (event) => {
    setName(event.target.value);
  };
  const handleEmail = (event) => {
    setEmail(event.target.value);
  };
  const handlePhone = (event) => {
    setPhone(event.target.value);
  };
  const handletext = (event) => {
    settext(event.target.value);
  };

  return (
    <>
      <Form>
        <Form.Group controlId="formName">
          <Form.Label>{form.name}</Form.Label>
          <Form.Control onChange={handleName} value={name} type="text" />
        </Form.Group>
        <Form.Group controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control onChange={handleEmail} value={email} type="emaill" />
        </Form.Group>
        <Form.Group controlId="formPhone">
          <Form.Label>{form.phone}</Form.Label>
          <Form.Control onChange={handlePhone} value={phone} type="text" />
        </Form.Group>
        <Form.Group controlId="formText">
          <Form.Label>{form.city}</Form.Label>
          <Form.Control onChange={handletext} value={text} type="text" />
        </Form.Group>
        <div className="text-center">
          <p status={status}>{message}</p>
          <Button size="sm" variant="primary" className="my-4" onClick={handleSubmit}>
            {form.button}
          </Button>
          <p className="info">
            {form.info} | {form.tel}
          </p>
        </div>
      </Form>
    </>
  );
};

ContactForm.propTypes = {
  form: PropTypes.object,
};

ContactForm.defaultProps = {
  form: {},
};

export default ContactForm;
