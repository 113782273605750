// Must be refactor Harcode content!!
import React from "react";
import Carousel from "react-multi-carousel";
import Image from "components/Image";
import "react-multi-carousel/lib/styles.css";
import "./SectionMaker.scss";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 768 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 2,
  },
};
const SectionMaker = () => {
  return (
    <Carousel
      responsive={responsive}
      showDots={false}
      sliderClass=""
      slidesToSlide={1}
      swipeable
      additionalTransfrom={0}
      arrows
      autoPlaySpeed={3000}
      centerMode={false}
      className=""
      containerClass="container-with-dots"
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
    >
      <div className="slide-box">
        <div className="slide-item">
          <Image className="img-fluid" fileName="climatizacion.png" alt="{subheader}" />
          <p>Climatización</p>
        </div>
      </div>
      <div className="slide-box">
        <div className="slide-item">
          <Image className="img-fluid" fileName="ducha.png" alt="{subheader}" />
          <p>Lámina de agua</p>
        </div>
      </div>
      <div className="slide-box">
        <div className="slide-item">
          <Image className="img-fluid" fileName="iluminacion.png" alt="{subheader}" />
          <p>Iluminación</p>
        </div>
      </div>
      <div className="slide-box">
        <div className="slide-item">
          <Image className="img-fluid" fileName="mozaico.png" alt="{subheader}" />
          <p>Revestimiento veneciano</p>
        </div>
      </div>
      <div className="slide-box">
        <div className="slide-item">
          <Image className="img-fluid" fileName="brochure.png" alt="{subheader}" />
          <p>Losetas térmicas</p>
        </div>
      </div>
      <div className="slide-box">
        <div className="slide-item">
          <Image className="img-fluid" fileName="accesorios.png" alt="{subheader}" />
          <p>Accesorios de instalación</p>
        </div>
      </div>
      <div className="slide-box">
        <div className="slide-item">
          <Image className="img-fluid" fileName="accesorios2.png" alt="{subheader}" />
          <p>Bombas, filtros y accesorios</p>
        </div>
      </div>
    </Carousel>
  );
};

export default SectionMaker;
