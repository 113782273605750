import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Row, Col } from "react-bootstrap";
import SectionHeader from "components/SectionHeader";
import PooltypesItems from "components/PooltypesItems";
import PageSection from "components/PageSection";
import "./Pooltypes.scss";

const Pooltypes = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const {
    anchor,
    header: rootHeader,
    subheader: rootSubHeader,
    pooltypes,
    imageFileNames,
  } = frontmatter;

  return (
    <PageSection className={clsx("Pooltypes-section", className)} id={anchor}>
      <Row>
        <Col>
          <SectionHeader className="mb-5 text-center" header={rootHeader} />
        </Col>
      </Row>
      <Row xs={1} md={2}>
        <Col className="pooltypes-items">
          <p dangerouslySetInnerHTML={{ __html: rootSubHeader }} />
          <ul className="pool-list mt-5">
            {pooltypes.map(({ poolItems }) => (
              <li key={poolItems}>{poolItems}</li>
            ))}
          </ul>
        </Col>
        <Col>
          <PooltypesItems imageFileName={imageFileNames} subheader={rootSubHeader} />
        </Col>
      </Row>
    </PageSection>
  );
};

Pooltypes.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Pooltypes.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Pooltypes;
