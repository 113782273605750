import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import Image from "components/Image";

import ContactForm from "components/ContactForm";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";

import "./Contact.scss";

const Contact = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader, form } = frontmatter;

  return (
    <>
      <div className="top">
        <Image className="img-fluid logo" fileName="contact-bot.png" alt="contact-top" />
      </div>
      <PageSection className={className} id={anchor}>
        <Row>
          <SectionHeader
            header={rootHeader}
            subheader={rootSubHeader}
            className="mb-4 text-center"
          />
        </Row>
        <Row>
          <Col md={{ span: 6, offset: 3 }} className="my-3">
            <div className="contact-form">
              <ContactForm {...form} />
            </div>
          </Col>
        </Row>
      </PageSection>
      <div className="bot">
        <Image className="img-fluid logo" fileName="contact-top.png" alt="contact-top" />
      </div>
    </>
  );
};

Contact.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Contact.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Contact;
