import React from "react";
import PropTypes from "prop-types";

import Image from "components/Image";

import "./PortfolioItem.scss";

const PortfolioItem = ({
  imageFileName,
  subheader
}) => {

  return (
    <>
      <Image
        className="img-fluid"
        fileName={imageFileName}
        alt={subheader}
      />
    </>
  );
};

PortfolioItem.propTypes = {
  imageFileName: PropTypes.string.isRequired,
  subheader: PropTypes.string,
};

PortfolioItem.defaultProps = {
  subheader: "",
};

export default PortfolioItem;
