import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Row, Col } from "react-bootstrap";
import Image from "components/Image";
import SectionOffice from "components/SectionOffice";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import "./Office.scss";

const Office = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const {
    anchor,
    header: rootHeader,
    subheader: rootSubHeader,
    office,
    officeList,
    officeIcon,
  } = frontmatter;

  return (
    <PageSection className={clsx("office-section", className)} id={anchor}>
      <Row>
        <Col>
          <SectionHeader className="text-center" header={rootHeader} />
        </Col>
      </Row>
      <Row xs={1} md={2}>
        <Col className="fibra-items">
          <Image className="img-fluid" fileName={officeIcon} alt={officeIcon} />
          <p dangerouslySetInnerHTML={{ __html: rootSubHeader }} />
          <ul className="office-list mt-5">
            {officeList.map(({ officeItems }) => (
              <li key={officeItems}>{officeItems}</li>
            ))}
          </ul>
        </Col>
        <Col className="fibra-img">
          {office.map(({ header, imageFileName, subheader }) => (
            <SectionOffice key={header + 1} imageFileName={imageFileName} subheader={subheader} />
          ))}
        </Col>
      </Row>
    </PageSection>
  );
};

Office.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Office.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Office;
