import React from "react";
import PropTypes from "prop-types";

import Image from "components/Image";

import "./PooltypesItems.scss";

const PortfolioItem = ({ imageFileName, subheader }) => {
  return (
    <>
      <Image className="img-fluid mb-3" fileName={imageFileName} alt={subheader} />
      <Image className="img-fluid" fileName="pile2bis.png" alt={subheader} />
    </>
  );
};

PortfolioItem.propTypes = {
  imageFileName: PropTypes.string.isRequired,
  subheader: PropTypes.string,
};

PortfolioItem.defaultProps = {
  subheader: "",
};

export default PortfolioItem;
