import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import SectionFaq from "components/SectionFaq";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";

import "./Faq.scss";

const Faq = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader, faq } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} className="mb-3" />
      </Row>
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          {faq.map(({ ...faqs }) => (
            <SectionFaq {...faqs} key={faqs.question} />
          ))}
        </Col>
      </Row>
    </PageSection>
  );
};

Faq.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Faq.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Faq;
