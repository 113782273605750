import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Row, Col } from "react-bootstrap";
import SectionHeader from "components/SectionHeader";
import PortfolioItem from "components/PortfolioItem";
import PageSection from "components/PageSection";
import "./Portfolio.scss";

const Portfolio = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader, portfolios } = frontmatter;

  return (
    <PageSection className={clsx("portfolio-section", className)} id={anchor}>
      <Row xs={1} md={2}>
        <Col>
          {portfolios.map(({ header, imageFileName, subheader }) => (
            <PortfolioItem key={header + 1} imageFileName={imageFileName} subheader={subheader} />
          ))}
        </Col>

        <Col>
          <SectionHeader className="my-auto" header={rootHeader} />
          <p dangerouslySetInnerHTML={{ __html: rootSubHeader }} />
        </Col>
      </Row>
    </PageSection>
  );
};

Portfolio.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Portfolio.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Portfolio;
