import React from "react";


import { Navbar, Container } from "react-bootstrap";

import "./CleanNavbar.scss";

const MyNavbar = () => {

  return (
    <Navbar
        className="navbar-root clean"
        expand="lg"
        >
        <Container>
          <p>clean</p>
        </Container>
    </Navbar>
  );
};


export default MyNavbar;
