import React from "react";
import PropTypes from "prop-types";

import Image from "components/Image";

import "./SectionOffice.scss";

const SectionOffice = ({
  imageFileName,
  subheader
}) => {

  return (
    <>
      <Image
        className="img-fluid"
        fileName={imageFileName}
        alt={subheader}
      />
    </>
  );
};

SectionOffice.propTypes = {
  imageFileName: PropTypes.string.isRequired,
  subheader: PropTypes.string,
};

SectionOffice.defaultProps = {
  subheader: "",
};

export default SectionOffice;
