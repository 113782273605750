// Must be Refactored!

import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import SectionMaker from "components/SectionMaker";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import "./Maker.scss";

const Maker = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row className="text-center mb-4">
        <SectionHeader header={rootHeader} />
        <Col>
          <p>{rootSubHeader}</p>
        </Col>
      </Row>
      <SectionMaker />
    </PageSection>
  );
};

Maker.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Maker.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Maker;
